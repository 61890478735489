import {setCookie, getCookie} from '../common/utils/fn';

export function showIEVRedirectPopup() {
  var popup = document.getElementById('popup-wrapper');
  var dataEdt = document.querySelector('body').dataset.edtn;

    function showPopup() {
      popup.style.display = 'block';
      document.body.style.overflow = 'hidden';

      window.onclick = function(event) {
        if (event.target == popup) {
          popup.style.display = 'none';
          document.body.style.overflow = 'auto';
        }
      };
    }

    (function(){
      var ire = getCookie('ire');
      var dire = getCookie('dire');

      if (ire != null) {
        var url = ire.substr(ire.indexOf(';') + 1, ire.length);
        var ire_substring = ire.substr(0, ire.indexOf(';'));
        var buttons = document.querySelectorAll('.popup_buttons .rdr-button');
        var text = document.querySelectorAll('.popup_text .rdr-text');

        for (let item of buttons) {
          if (item.classList.contains(dataEdt)) {
            item.classList.add('home');
            item.removeAttribute('href');
            item.addEventListener('click', event => {
              popup.style.display = 'none';
              document.body.style.overflow = 'auto';
              setCookie('dire', true, 1000);
            });
          } else if (item.classList.contains(ire_substring)) {
            item.setAttribute('href', url);
          } else if (!item.classList.contains(dataEdt) && !item.classList.contains(ire_substring)) {
            item.parentNode.removeChild(item);
          }
        }

        for (let item of text) {
          if (!item.classList.contains(ire_substring)) {
            item.parentNode.removeChild(item);
          }
        }

        if (ire != null && ire_substring != dataEdt && dire == null) {
          showPopup();
        }
      }
    })
}